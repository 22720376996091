<template>
  <div>
    <portal to="header">
      <terminal-title :text="$t('Returns')" />
    </portal>

    <portal to="settings">
      <tiles :schema="tilesSchema" />
    </portal>

    <div>
      <q-table
        :style="`height: ${terminalContainerHeight}px`"
        row-key="id"
        :rows-per-page-label="$t('Rows per page')"
        :rows="returnParcels"
        :columns="columns"
        v-model:pagination="pagination"
        :loading="returnParcelsLoading"
        :filter="filter"
        :rows-per-page-options="[]"
        table-header-class="d-none"
        virtual-scroll
        binary-state-sort
        flat
        @request="onRequest"
      >
        <template v-slot:loading>
          <q-inner-loading
            showing
            color="primary"
          />
        </template>

        <template v-slot:body="props">
          <q-tr
            :props="props"
            class="clickable"
            @dblclick="onRowDblClick(props.row)"
          >
            <q-td
              key="details"
              :props="props"
            >
              <div class="row items-center q-pt-xs">
                <q-badge
                  :color="stateColors[props.row.state]"
                  :label="$t(props.row.state)"
                  class="q-py-xs"
                />

                <div class="col q-px-sm text-caption text-capitalize">
                  {{ props.row.type }}
                </div>

                <div v-if="props.row.created">
                  {{ $moment(props.row.created.date).format($appOptions.formats.date) }}
                </div>
              </div>

              <div
                v-if="props.row._embedded && props.row._embedded.warehouse"
                class="text-caption"
              >
                <small>{{ props.row._embedded.warehouse.name }}</small>
              </div>

              <div class="text-caption">
                {{ props.row.comment }}
              </div>

              <div class="text-subtitle2 text-bold">
                {{ props.row.id }}
              </div>
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ReturnParcels',
  data () {
    return {
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: this.$t('Details'),
          name: 'details',
          align: 'left'
        }
      ],
      stateColors: {
        new: 'info',
        confirmed: 'yellow',
        complete: 'success',
        deleted: 'danger',
        preset: 'warning',
        archived: 'dark'
      }
    }
  },
  computed: {
    ...mapGetters([
      'returnParcels',
      'returnParcelsLoading',
      'terminalContainerHeight'
    ]),
    tilesSchema () {
      return [
        {
          id: 1,
          label: this.$t('Add'),
          icon: 'add',
          value: true,
          onChanged: () => {
            this.create()
          }
        },
        {
          id: 2,
          label: this.$t('Refresh'),
          icon: 'refresh',
          value: true,
          onChanged: () => {
            this.loadDefaultItems()
          }
        }
      ]
    }
  },
  mounted () {
    this.loadDefaultItems()
  },
  methods: {
    ...mapActions([
      'loadReturnParcels'
    ]),
    loadDefaultItems () {
      const pagination = {
        per_page: 25,
        page: 1,
        'order-by': [
          {
            type: 'field',
            field: 'created',
            direction: 'desc'
          }
        ]
      }

      return this.onRequest({ pagination })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = this.$utils.buildQuery(this.pagination)

      return this.loadReturnParcels(query)
        .then(({ page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }
        })
    },
    create () {
      this.$router.push('/terminal/return-parcel')
    },
    onRowDblClick (row) {
      this.$router.push('/terminal/return-parcel/' + row.id)
    }
  }
}
</script>
